<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Item">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">
            <div v-for="(item,index) in forms" :key="index">
              <b-row>
                <b-col md="12">
                  <b-button class="float-right" variant="danger" @click="removeRow(index);">-</b-button>
                </b-col>
              </b-row>

              <b-row>
                
                <b-col md="4" >
                  
                  <b-form-group
                    label="Project Site"
                    class="required"

                  >
                    <b-form-select v-model="item.site">

                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      
                      <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                      
                      
                    </b-form-select>
                      
                  </b-form-group>
                </b-col>


                <b-col md="4">
                  <b-form-group
                    label="Item Name"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.item"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Chemical Name"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.chemical_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Vendor"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.vendor"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Total Quantity"
                    class=""
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.total_quantity"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Location"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.location"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Delivery Frequency"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.delivery_frequency"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="UOM"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder="3 to 100 characters"
                      v-model="item.uom"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Safety Level"
                    class="required"
                  >
                    <b-form-input
                      autocomplete="off"
                      placeholder=""
                      v-model="item.safety_level"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

              <hr>
            </div>

            <b-row class="mt-1">

                <b-col md="12" class="text-center">
                    
                    <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                </b-col>
            </b-row>
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'stock-inventory-items', params: { site_id: $route.params.site_id } })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      forms :[{
        site : '',
        item:'',
        chemical_name:'',
        vendor:'',
        total_quantity:'',
        location:'',
        delivery_frequency:'',
        uom:'',
        safety_level:'',
      }],
      sites:[],
      
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.forms
           },
           api: '/api/add-stock-inventory-items'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'stock-inventory-items', params: { site_id: this.$route.params.site_id } })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:{name:'stock-inventory-form'},
        text:'Stock Inventory Form',             
      },{
        to:{name:'stock-inventory-items',params: { site_id: this.$route.params.site_id }},
        text: 'Stock Inventory Items',
        
      },{
        to:null,
        text:'Add Item',
        active:true
      }];
      return item;
    },
    addRow(){
        this.forms.push({
          site : this.$route.params.site_id ? this.$route.params.site_id : '',
          item:'',
          chemical_name:'',
          vendor:'',
          total_quantity:'',
          location:'',
          delivery_frequency:'',
          uom:'',
          safety_level:'',
        })
    },

    removeRow(index) {
      
      this.forms.splice(index, 1);

      if(this.forms.length == 0){
          this.forms.push({
            site : this.$route.params.site_id ? this.$route.params.site_id : '',
            item:'',
            chemical_name:'',
            vendor:'',
            total_quantity:'',
            location:'',
            delivery_frequency:'',
            uom:'',
            safety_level:'',
          })
      }
    },
    

  },
  mounted(){
    this.allSites().then(()=> {
      this.forms[0].site = this.$route.params.site_id ? this.$route.params.site_id : '';
    });
  }
}
</script>
